import dataService from '../services/data.service'
import userService from '../services/user.service'

const initialState = { openSidebar: false, profile: {}, singleProfile: {}, searchProfiles: [], services: [] }

const state = initialState
const getters = {
  sidebarStatus (state) {
    return state.openSidebar
  },
  getprofileInfo (state) {
    return state.profile
  },
  getUserProfile (state) {
    return state.singleProfile
  }

}
const actions = {
  async toggleSidebar ({ commit }) {
    commit('updateToggleSidebar')
  },
  async getServices ({ commit }) {
    return dataService.getServices().then(
      data => {
        commit('servicesSuccess', data)
        return Promise.resolve(data)
      },
      error => {
        commit('servicesFailure')
        return Promise.reject(error)
      }
    )
  },

  async getTasks ({ commit }) {
    return dataService.getTasks().then(
      data => {
        commit('tasksSuccess', data)
        return Promise.resolve(data)
      },
      error => {
        commit('tasksFailure')
        return Promise.reject(error)
      }
    )
  },
  async getTask ({ commit }, { id }) {
    return dataService.getTask(id).then(
      data => {
        commit('taskSuccess', data)
        return Promise.resolve(data)
      },
      error => {
        commit('taskFailure')
        return Promise.reject(error)
      }
    )
  },

  async updateProfile ({ commit }, profile) {
    return userService.updateProfile(profile).then(
      user => {
        commit('profileUpdateSuccess', user)
        return Promise.resolve(user)
      },
      error => {
        commit('profileUpdateFailure')
        return Promise.reject(error)
      }
    )
  },
  async verifyId ({ commit }, data) {
    return userService.verifyIdentity(data).then(
      user => {
        return Promise.resolve(user)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },

  setUserProfile ({ commit }, profile) {
    commit('setUserProfileSuccess', profile)
  },
  async profileSearch ({ commit, dispatch }, id) {
    return dataService.searchProfile(id)
      .then((res) => {
        commit('getProfileSearchSuccess', res)
        return Promise.resolve(res)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch('auth/logout', null, { root: true })
        }
        if (error.response.status === 500) {
          return false
        }
        return Promise.reject(error)
      })
  },
  async sendMessage ({ commit, dispatch }, data) {
    console.log('sendMessage data', data)
    return dataService.sendMessage(data)
      .then((res) => {
        console.log('sendMessage', res)
        return Promise.resolve(res)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch('auth/logout', null, { root: true })
        }
        if (error.response.status === 500) {
          return false
        }
        return Promise.reject(error)
      })
  },
  async submitRating ({ commit, dispatch }, data) {
    console.log('submitRating data', data)
    return dataService.submitRating(data)
      .then((res) => {
        console.log('submitRating', res)
        return Promise.resolve(res)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch('auth/logout', null, { root: true })
        }
        if (error.response.status === 500) {
          return false
        }
        return Promise.reject(error)
      })
  },
  async getProfile ({ commit }) {
    return userService.getProfile().then(
      user => {
        commit('profileSuccess', user)
        return Promise.resolve(user)
      },
      error => {
        commit('profileFailure')
        return Promise.reject(error)
      }
    )
  }
}

const mutations = {
  profileSuccess (state, user) {
    state.profile = user
  },
  setUserProfileSuccess (state, profile) {
    state.singleProfile = profile
  },
  updateToggleSidebar (state) {
    state.openSidebar = !state.openSidebar
  },
  profileFailure (state) {
    state.profile = null
  },
  profileUpdateSuccess (state, user) {
    state.profile = user
  },
  getProfileSearchSuccess (state, profile) {
    state.searchProfiles = profile
  },
  profileUpdateFailure (state) {
    state.profile = null
  },
  servicesSuccess (state, services) {
    state.services = services.results
  },
  servicesFailure (state) {
    state.services = null
  },
  tasksSuccess (state, tasks) {
    state.tasks = tasks
  },
  tasksFailure (state) {
    state.tasks = null
  },
  taskSuccess (state, task) {
    state.task = task
  },
  taskFailure (state) {
    state.task = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
