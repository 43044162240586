<template>
  <form @submit.prevent="onSubmit" novalidate>
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          class="mb-3"
          placeholder="First Name"
          v-model="v$.first_name.$model"
          :icon="faUser"
          :errors="v$?.first_name?.$errors[0]?.$message"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          class="mb-3"
          placeholder="Last Name"
          v-model="v$.last_name.$model"
          :icon="faUser"
          :errors="v$?.last_name?.$errors[0]?.$message"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          class="mb-3"
          placeholder="Address Line 1"
          v-model="v$.address_line_1.$model"
          :icon="faMapMarkerAlt"
          :errors="v$?.address_line_1?.$errors[0]?.$message"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          class="mb-3"
          placeholder="Address Line 2"
          v-model="v$.address_line_2.$model"
          :icon="faMapMarkerAlt"
          :errors="v$?.address_line_2?.$errors[0]?.$message"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          class="mb-3"
          placeholder="City"
          v-model="v$.city.$model"
          :icon="faMapMarkerAlt"
          :errors="v$?.city?.$errors[0]?.$message"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <div class="relative py-1">
          <FontAwesomeIcon
            class="absolute z-10 text-xl top-5 left-2 text-custom-green"
            :icon="faGlobeAmericas"
          />
          <select
            class="block w-full py-4 pl-10 mb-3 text-lg border-0 shadow focus:border-b-1 focus:border-custom-green focus:ring-0 focus:shadow-lg ring-2 ring-gray-100"
            placeholder="Province"
            v-model="v$.province.$model"
            aria-label="Select a province"
          >
            <option value="" selected disabled class="text-red-400">
              Province
            </option>
            <option
              v-for="province in provinces"
              :value="province"
              :key="province"
              class="py-4 text-lg"
            >
              {{ province }}
            </option>
          </select>
          <div v-if="v$?.province?.$errors[0]?.$message" class="text-red-500 text-sm">
            {{ v$?.province?.$errors[0]?.$message }}
          </div>
        </div>
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          class="mb-3 uppercase"
          placeholder="Postal Code"
          v-model="v$.postal_code.$model"
          inputClass="uppercase"
          :icon="faMapMarkedAlt"
          :errors="v$?.postal_code?.$errors[0]?.$message"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          class="mb-3"
          type="tel"
          placeholder="Phone number"
          v-model="v$.phone_number.$model"
          onkeydown="return ( event.ctrlKey || event.altKey
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9)
                    || (event.keyCode>34 && event.keyCode<40)
                    || (event.keyCode==46) )"
          :icon="faPhoneAlt"
          :errors="v$?.phone_number?.$errors[0]?.$message"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          class="w-full col-span-2 mb-3"
          placeholder="Enter Email"
          v-model="v$.email.$model"
          :icon="faEnvelope"
          :errors="v$?.email?.$errors[0]?.$message"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          type="password"
          v-model="v$.password.$model"
          placeholder="Enter Password"
          :icon="faLock"
          :errors="v$?.password?.$errors[0]?.$message"
        />
        <p class="text-sm text-red-500" v-if="passError">{{ passError }}</p>
      </div>
      <div class="col-span-2 sm:col-span-1">
        <TextInput
          type="password"
          class="mb-5"
          v-model="v$.confirm_password.$model"
          placeholder="Confirm Password"
          :icon="faLock"
          :errors="v$?.confirm_password?.$errors[0]?.$message"
        />
      </div>
    </div>
    <SubmitButton class="px-20 mx-auto mb-5" :isLoading="isLoading"
      >Register</SubmitButton
    >
    <ul
      v-if="errors.api"
      class="grid-cols-2 p-4 font-medium text-red-800 rounded-md bg-red-50"
    >
      <li v-for="(errors, field) in errors.api" :key="field">
        <strong>{{ field }}:</strong>
        <ul>
          <li v-for="(error, index) in errors" :key="index">- {{ error }}</li>
        </ul>
      </li>
    </ul>
  </form>
</template>
<script>
import {
  faEnvelope,
  faGlobeAmericas,
  faLock,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faPhoneAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { reactive, ref } from '@vue/reactivity'
import { useVuelidate } from '@vuelidate/core'
import {
  email,
  helpers,
  minLength,
  maxLength,
  required
} from '@vuelidate/validators'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SubmitButton from '../SubmitButton.vue'
import TextInput from '../TextInput.vue'

export default {
  components: { TextInput, SubmitButton, FontAwesomeIcon },
  setup () {
    const isLoading = ref(false)
    const passError = ref('')
    const errors = ref('')
    const store = useStore()
    const router = useRouter()

    const provinces = ref([
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Nova Scotia',
      'Northwest Territories',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Québec',
      'Saskatchewan',
      'Yukon'
    ])
    const state = reactive({
      first_name: '',
      last_name: '',
      address_line_1: '',
      address_line_2: '',
      phone_number: '',
      city: '',
      postal_code: '',
      email: '',
      password: '',
      confirm_password: '',
      province: ''
    })
    const postalCodeFormat = (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true
      }
      // helpers.regex('postalCodeFormat', /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i)
      return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
        value
      )
    }

    const passwordValidator = (value) => {
      const regex =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
      return regex.test(value)
    }

    const validProvince = (value) => {
      return provinces.value.includes(value)
    }

    const rules = {
      first_name: {
        required: helpers.withMessage('First name is required.', required)
      },
      last_name: {
        required: helpers.withMessage('Last name is required.', required)
      },
      address_line_1: {
        required: helpers.withMessage('Address is required.', required)
      },
      address_line_2: {
        // required: helpers.withMessage('Address is required.', required)
      },
      phone_number: {
        required: helpers.withMessage('Phone is required.', required),
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      city: { required: helpers.withMessage('City is required.', required) },
      postal_code: {
        required: helpers.withMessage('Postal code is required.', required),
        postalCodeFormat: helpers.withMessage(
          'Postal code is invalid. Ex. A1A 1A1',
          postalCodeFormat
        )
      },
      email: {
        required: helpers.withMessage('Email field is required', required),
        email: helpers.withMessage(
          'Email should be a valid email address ',
          email
        )
      },
      password: {
        required: helpers.withMessage('Password field is required', required),
        minLength: minLength(8),
        passwordValidator: helpers.withMessage(
          'Password must contain at least one uppercase letter, one number, and one special character.',
          passwordValidator
        )
      },
      confirm_password: {
        required: helpers.withMessage(
          'Confirm Password field is required',
          required
        ),
        minLength: minLength(6)
        // sameAsPassword: sameAs(state.password)
      },
      province: {
        required: helpers.withMessage('Province is required.', required),
        validProvince: helpers.withMessage('Province is invalid.', validProvince)
      }
    }
    const v$ = useVuelidate(rules, state)

    const onSubmit = () => {
      passError.value = ''
      if (state.password !== state.confirm_password) {
        passError.value = 'Password and confirm  password does not match'
        return
      }
      v$.value.$touch()

      if (v$.value.$invalid) return

      isLoading.value = true
      errors.value = null
      store
        .dispatch('auth/SignUp', state)
        .then(
          (data) => {
            router.push({
              name: 'Success'
            })
          },
          (error) => {
            if (error.response.status === 500) {
              errors.value = 'Something went wrong please try again.'
            } else if (error.response.status === 400) {
              errors.value = { api: error.response.data.errors }
            } else {
              errors.value =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error ||
                error.toString()
            }
          }
        )
        .then(function () {
          isLoading.value = false
        })
    }
    return {
      onSubmit,
      v$,
      faEnvelope,

      faLock,
      faMapMarkedAlt,
      faPhoneAlt,
      state,
      faMapMarkerAlt,
      faGlobeAmericas,
      errors,
      passError,
      faUser,
      provinces,
      isLoading
    }
  }
}
</script>

<style>
/* Mobile-specific styles */
@media (max-width: 768px) {
  select {
    font-size: 1.25rem; /* Larger font size */
    padding: 1rem; /* Increased padding */
  }

  option {
    font-size: 1.25rem; /* Larger font size for options */
    padding: 0.75rem; /* Extra spacing for better touch usability */
  }
}
</style>
