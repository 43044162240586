<template>
  <form @submit.prevent="onSubmit" novalidate id="profileform">
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <LabelTextInput
        class="mb-5"
        label="First Name"
        v-model="v$.first_name.$model"
        :icon="faEnvelope"
        :errors="v$?.first_name?.$errors[0]?.$message"
      />
      <LabelTextInput
        class="mb-5"
        label="Last Name"
        v-model="v$.last_name.$model"
        :icon="faEnvelope"
        :errors="v$?.last_name?.$errors[0]?.$message"
      />
      <LabelTextInput
        class="mb-5"
        label="Address Line 1"
        v-model="v$.address_line_1.$model"
        :icon="faEnvelope"
        :errors="v$?.address_line_1?.$errors[0]?.$message"
      />
      <LabelTextInput
        class="mb-5"
        label="Address Line 2"
        v-model="v$.address_line_2.$model"
        :icon="faEnvelope"
        :errors="v$?.address_line_2?.$errors[0]?.$message"
      />

      <LabelTextInput
        class="mb-5"
        label="City"
        v-model="v$.city.$model"
        :icon="faEnvelope"
        :errors="v$?.city?.$errors[0]?.$message"
      />

       <div class="relative ">
  <label  class="mb-1 text-xl font-semibold text-indigo-900" for="">Province</label>

      <select
        class="block w-full py-3 pl-3 mt-1 mb-3 text-lg border-0 shadow focus:border-b-1 focus:border-custom-green focus:ring-0 focus:shadow-lg ring-2 ring-gray-100"
        placeholder="Province"
        v-model="v$.province.$model"
        :errors="v$?.province?.$errors[0]?.$message"
      >
      <option value="" selected disabled class="text-gray-300">Province</option>
      <option v-for="province in provinces" :value="province" :key="province "> {{ province  }} </option>
      </select>
      </div>
      <LabelTextInput
        class="mb-5"
        inputClass="uppercase"
        label="Postal Code"
        v-model="v$.postal_code.$model"
        :icon="faEnvelope"
        :errors="v$?.postal_code?.$errors[0]?.$message"
      />

      <LabelTextInput
        class="mb-5"
        label="Phone number"
        v-model="v$.phone_number.$model"
        :icon="faEnvelope"
        onkeydown="return ( event.ctrlKey || event.altKey
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9)
                    || (event.keyCode>34 && event.keyCode<40)
                    || (event.keyCode==46) )"
        :errors="v$?.phone_number?.$errors[0]?.$message"
      />
    </div>
    <div class="grid grid-cols-1 gap-4">
      <LabelTextArea
        class="mb-5"
        label="Bio"
        v-model="v$.bio.$model"
        :icon="faEnvelope"
        :errors="v$?.bio?.$errors[0]?.$message"
      />
    </div>
    <div class="grid grid-cols-1 gap-4 mb-5">
    <label class="mb-1 font-semibold text-black" for="">Profile Photo</label>
      <input type="file" @change="uploadFile" class="w-auto bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"/>
    </div>

<div v-if="v$?.services?.$errors[0]?.$message" class="px-5 py-3 text-red-900 bg-red-100 rounded-sm">{{v$?.services?.$errors[0]?.$message}}</div>
    <SubmitButton class="px-20 my-5" :isLoading="isLoading"
      >Update Profile</SubmitButton
    >
    <div class="relative w-full">
      <div
        v-if="errors"
        class="absolute w-full p-4 font-medium text-red-800 rounded-md bg-red-50"
      >
        {{ errors }}
      </div>
        <div class="flex items-center px-4 py-2 mb-5 font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded text-md" v-if="messageAlert"> {{ messageAlert }}</div>

    </div>
  </form>
</template>
<script>
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import { reactive, ref } from '@vue/reactivity'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, requiredIf } from '@vuelidate/validators'
import axios from 'axios'
import { useStore } from 'vuex'
import LabelTextArea from './LabelTextArea.vue'
import LabelTextInput from './LabelTextInput.vue'
import SubmitButton from './SubmitButton.vue'

export default {
  components: { SubmitButton, LabelTextInput, LabelTextArea },
  setup (props, context) {
    const isLoading = ref(false)
    const passError = ref('')
    const messageAlert = ref('')
    const errors = ref('')
    const store = useStore()
    const servicesData = store.state.data.services
    const profile = ref(store.state.data.profile)

    const provinces = ref([
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Nova Scotia',
      'Northwest Territories',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Québec',
      'Saskatchewan',
      'Yukon'
    ])
    const state = reactive({
      first_name: profile?.value.first_name ?? '',
      last_name: profile?.value.last_name ?? '',
      address_line_1: profile?.value.address_line_1 ?? '',
      address_line_2: profile?.value.address_line_2 ?? '',
      phone_number: profile?.value.phone_number ?? '',
      city: profile?.value.city ?? '',
      postal_code: profile?.value.postal_code ?? '',
      province: profile?.value.province ?? '',
      bio: profile?.value.bio ?? '',
      hour_rate: profile?.value.hour_rate ?? '',
      is_service_provider: profile?.value.is_service_provider ?? false,
      is_volunteer: profile?.value.is_volunteer ?? false,
      services: profile?.value.services ?? [],
      photo_url: profile?.value.photo_url
    })
    const postalCodeFormat = value => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true
      }
      // helpers.regex('postalCodeFormat', /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i)
      return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(value)
    }
    const rules = {
      first_name: {
        required: helpers.withMessage('First name is required.', required)
      },
      last_name: {
        required: helpers.withMessage('Last name is required.', required)
      },
      address_line_1: {
        required: helpers.withMessage('Address is required.', required)
      },
      address_line_2: {
        // required: helpers.withMessage('Address is required.', required)
      },
      phone_number: {
        required: helpers.withMessage('Phone is required.', required)
      },
      city: { required: helpers.withMessage('City is required.', required) },
      postal_code: {
        required: helpers.withMessage('Postal code is required.', required),
        postalCodeFormat: helpers.withMessage('Postal code is invalid. Ex. A1A 1A1', postalCodeFormat)
      },
      province: {
        required: helpers.withMessage('Province is required.', required)
      },
      bio: {
        required: helpers.withMessage('Bio is required', required)
      },
      is_service_provider: {
        required: helpers.withMessage('Service provider field is required', required)
      },
      is_volunteer: {
        required: helpers.withMessage('Volunteer field is required', required)
      },
      hour_rate: {
        required: requiredIf(() => { return state.is_service_provider && !state.is_volunteer })// requiredIf(state.is_service_provider)
      },
      services: {
        required: requiredIf(() => { return state.is_service_provider })// requiredIf(state.is_service_provider)

      }
    }
    const v$ = useVuelidate(rules, state)

    const onSubmit = () => {
      v$.value.$touch()

      if (v$.value.$invalid) {
        document.getElementById('profileform').scrollIntoView({ behavior: 'smooth' })
        return
      }

      isLoading.value = true
      errors.value = null
      store
        .dispatch('data/updateProfile', state)
        .then(
          (data) => {
            messageAlert.value = 'Profile updated successfully.'
            profile.value = store.state.data.profile
            context.emit('profileUpdate')
          },
          (error) => {
            const err =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error ||
                error.toString()
            console.log('err', err)
            isLoading.value = false
          }
        )
        .then(function () {
          isLoading.value = false
        })
    }

    const uploadFile = async (e) => {
      const file = e.target.files[0]
      isLoading.value = true
      const formData = new FormData()
      formData.append('file', file)
      await axios.put('/upload/' + file.name, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          console.log(response)
          state.photo_url = response.data.link
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          isLoading.value = false
        })
      // fileUploadUrl
    }

    return {
      onSubmit,
      v$,
      faEnvelope,
      faLock,
      state,
      provinces,
      errors,
      uploadFile,
      servicesData,
      messageAlert,
      passError,
      isLoading
    }
  }
}
</script>
