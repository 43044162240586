<template>
  <div class="min-h-screen">
    <div class="flex justify-center flex-1">
      <svg
        class="w-16 h-16 text-custom-green animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        v-if="isLoading"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
    <div></div>

    <div v-if="!isLoading" class="flex bg-white flex-col h-full">
      <div class="w-full p-6">
        <div class="overflow-x-auto flex flex-col gap-3 cursor-pointer">
          <div
            class="flex w-full border px-4 py-2 rounded-md justify-between flex-wrap gap-4"
          >
            <div class="flex flex-col justify-between gap-5 w-9/12">
              <div>
                <h1 class="text-custom-green">message:</h1>
                <h1>{{ task?.message }}</h1>
              </div>
              <div class="flex gap-3">
                <h1 class="text-custom-green">Date:</h1>
                <h1>{{ task.created_at?.split("T")[0] }}</h1>
              </div>
            </div>
            <div class="flex gap-6 ml-auto">
              <div class="flex flex-col items-center gap-2">
                <h1 class="text-center text-custom-green">Client</h1>

                <template v-if="task.request_user?.profile?.photo_url">
                 <img
              :src="task.request_user?.profile?.photo_url"
              class="object-cover object-center w-20 h-20 rounded-full"
              />
          </template>
          <img
            v-else
            src="../assets/default_profile.png"
              class="object-cover object-center w-20 h-20 rounded-full"
          />

                <h1 class="text-center">
                  {{
                    task.type === "requested"
                      ? "You"
                      : task.request_user?.first_name +
                        " " +
                        task.request_user?.last_name
                  }}
                </h1>
              </div>
              <div class="flex flex-col items-center gap-2">
                <h1 class="text-center text-custom-green">Tasker</h1>
                <template v-if="task.target_user?.profile?.photo_url">
                 <img
              :src="task.target_user?.profile?.photo_url"
              class="object-cover object-center w-20 h-20 rounded-full"
              />
          </template>
          <img
            v-else
            src="../assets/default_profile.png"
              class="object-cover object-center w-20 h-20 rounded-full"
          />
                <h1 class="text-center">
                  {{
                    task.type === "received"
                      ? "You"
                      : task.target_user?.first_name +
                        " " +
                        task.target_user?.last_name
                  }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="task.request_user_review" class="p-6">
        <h1 class="text-custom-green mb-3">Client review</h1>
        <div
          class="flex w-full flex-wrap border px-4 py-2 rounded-md justify-between gap-3"
        >
          <div class="flex flex-col gap-4 w-10/12">
            <vue3-star-ratings
              :showControl="false"
              :disableClick="true"
              v-model="task.request_user_review.score"
            />
            <h1>{{ task.request_user_review?.message }}</h1>
            <div class="flex gap-3">
              <h1 class="text-custom-green">Date:</h1>
              <h1>{{ task.request_user_review?.created_at?.split("T")[0] }}</h1>
            </div>
          </div>
          <div class="flex flex-col p-3 items-center gap-3 ml-auto">

            <template v-if="task.request_user?.profile?.photo_url">
                 <img
              :src="task.request_user?.profile?.photo_url"
              class="object-cover object-center w-20 h-20 rounded-full"
              />
          </template>
          <img
            v-else
            src="../assets/default_profile.png"
              class="object-cover object-center w-20 h-20 rounded-full"
          />
            <h1 class="text-center">
              {{
                task.request_user?.first_name +
                " " +
                task.request_user?.last_name
              }}
            </h1>
          </div>
        </div>
      </div>
      <div
        v-else-if="task.type == 'requested'"
        class="px-5 py-2 bg-white shadow sm:px-6 sm:py-8"
      >
        <h1 class="text-custom-green mb-3">Client review</h1>

        <h2 class="text-2xl mb-4">Submit rating</h2>
        <p>You can submit your experience with tasker.</p>
        <form class="mt-4" @submit.prevent="submitRating" novalidate>
          <div class="items-center flex space-x-2">
            <vue3-star-ratings v-model="form.rating" :showControl="false" />
            <span class="pt-1">{{ form.rating }}</span>
          </div>
          <LabelTextArea
            class="my-5 w-full"
            inputClass="w-full"
            label="Comment"
            v-model="form.comment"
          />
          <SubmitButton class="w-auto mb-5" :isLoading="isLoading"
            >Submit
          </SubmitButton>
          <p class="text-green-500" v-if="isReviewSubmitted">
            Your review submitted successfully. Go to Home
            <a class="text-darkGreen" href="/">Click here</a>
          </p>
        </form>
      </div>
      <div v-if="task.target_user_review" class="p-6">
        <h1 class="text-custom-green mb-3">Tasker review</h1>
        <div
          class="flex w-full flex-wrap border px-4 py-2 rounded-md justify-between gap-3"
        >
          <div class="flex flex-col gap-4 w-10/12">
            <vue3-star-ratings
              :showControl="false"
              :disableClick="true"
              v-model="task.target_user_review.score"
            />
            <h1>{{ task.target_user_review?.message }}</h1>
            <div class="flex gap-3">
              <h1 class="text-custom-green">Date:</h1>
              <h1>{{ task.target_user_review?.created_at?.split("T")[0] }}</h1>
            </div>
          </div>
          <div class="flex flex-col p-3 items-center gap-3 ml-auto">
            <template v-if="task.target_user?.profile?.photo_url">
                 <img
              :src="task.target_user?.profile?.photo_url"
              class="object-cover object-center w-20 h-20 rounded-full"
              />
          </template>
          <img
            v-else
            src="../assets/default_profile.png"
              class="object-cover object-center w-20 h-20 rounded-full"
          />
            <h1 class="text-center">
              {{
                task.target_user?.first_name + " " + task.target_user?.last_name
              }}
            </h1>
          </div>
        </div>
      </div>
      <div
        v-else-if="task.type == 'received'"
        class="px-5 py-2 bg-white shadow sm:px-6 sm:py-8"
      >
        <h1 class="text-custom-green mb-3">Tasker review</h1>

        <h2 class="text-2xl mb-4">Submit rating</h2>
        <p>You can submit your experience with tasker.</p>
        <form class="mt-4" @submit.prevent="submitRating" novalidate>
          <div class="items-center flex space-x-2">
            <vue3-star-ratings v-model="form.rating" :showControl="false" />
            <span class="pt-1">{{ form.rating }}</span>
          </div>
          <LabelTextArea
            class="my-5 w-full"
            inputClass="w-full"
            label="Comment"
            v-model="form.comment"
          />
          <SubmitButton class="w-auto mb-5" :isLoading="isLoading"
            >Submit
          </SubmitButton>
          <p class="text-green-500" v-if="isReviewSubmitted">
            Your review submitted successfully. Go to Home
            <a class="text-darkGreen" href="/">Click here</a>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import LabelTextArea from '@/components/LabelTextArea.vue'
import vue3StarRatings from 'vue3-star-ratings'
import SubmitButton from '@/components/SubmitButton.vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: { vue3StarRatings, LabelTextArea, SubmitButton },
  setup (props) {
    const isLoading = ref(false)
    const store = useStore()
    const route = useRoute()
    const task = ref({})
    const form = ref({
      rating: null,
      comment: null
    })
    const isReviewSubmitted = ref(false)
    const router = useRouter()

    onMounted(async () => {
      await store.dispatch('data/getTask', { id: route.params.id }).catch(e => router.push({ path: '/tasks' }))

      task.value = store.state.data.task
      isLoading.value = false
    })

    const submitRating = async () => {
      isLoading.value = true

      const res = await store.dispatch('data/submitRating', {
        score: form.value.rating,
        message: form.value.comment,
        task: task.value.id
      })
      isLoading.value = true
      if (res) {
        isReviewSubmitted.value = true
        await store.dispatch('data/getTask', { id: route.params.id })
        task.value = store.state.data.task
      }
      isLoading.value = false
    }
    return { isLoading, task, submitRating, isReviewSubmitted, form }
  }
}
</script>
