<template>
  <div class="min-h-screen">
    <div class="flex justify-center flex-1">
      <svg
        class="w-16 h-16 text-custom-green animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        v-if="isLoading"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <div v-if="!isLoading" class="flex bg-white flex-col h-full">
      <div class="w-full p-6 flex justify-between">
        <h3 class="text-2xl font-bold uppercase">Tasks</h3>
        <div class="border rounded-md overflow-hidden">
          <button
            @click="toggleView('requested')"
            class="py-2 px-2"
            :class="{
              'text-white': currentView === 'requested',
              'bg-custom-green': currentView == 'requested',
            }"
          >
            Requested
          </button>
          <button
            @click="toggleView('received')"
            :class="{
              'text-white': currentView === 'received',
              'bg-custom-green': currentView == 'received',
            }"
            class="py-2 px-2"
          >
            Received
          </button>
        </div>
      </div>

      <div class="w-full p-6" v-if="currentView === 'received'">
        <h3 class="mb-5 text-2xl font-bold uppercase">Received</h3>
        <div class="overflow-x-auto flex flex-col gap-3 cursor-pointer">
          <div
            v-for="task in tasks.received"
            :key="task.id"
            class="flex w-full border px-4 py-2 rounded-md justify-between flex-wrap gap-4"
            @click="navigateToTask(task.id)"
          >

            <div class="flex flex-col justify-between gap-5 w-9/12">
              <div>
                <h1 class="text-custom-green">message:</h1>
                <h1>{{ task?.message }}</h1>
              </div>
              <div class="flex gap-3">
                <h1 class="text-custom-green">Date:</h1>
                <h1>{{ task.created_at?.split("T")[0] }}</h1>
              </div>
            </div>
            <div class="flex gap-6 ml-auto">
              <div class="flex flex-col items-center gap-2">
                <h1 class="text-center text-custom-green">Client</h1>

                <template v-if="task.request_user?.profile?.photo_url">
                 <img
              :src="task.request_user?.profile?.photo_url"
              class="object-cover object-center w-20 h-20 rounded-full"
              />
          </template>
          <img
            v-else
            src="../assets/default_profile.png"
              class="object-cover object-center w-20 h-20 rounded-full"
          />

                <h1 class="text-center">
                  {{
                    task.type === "received"
                      ? "You"
                      : task.request_user?.first_name +
                        " " +
                        task.request_user?.last_name
                  }}
                </h1>
              </div>
              <div class="flex flex-col items-center gap-2">
                <h1 class="text-center text-custom-green">Tasker</h1>

                <template v-if="task.target_user?.profile?.photo_url">
                 <img
              :src="task.target_user?.profile?.photo_url"
              class="object-cover object-center w-20 h-20 rounded-full"
              />
          </template>
          <img
            v-else
            src="../assets/default_profile.png"
              class="object-cover object-center w-20 h-20 rounded-full"
          />
                <h1 class="text-center">
                  {{
                    task.type === "requested"
                      ? "You"
                      : task.target_user?.first_name +
                        " " +
                        task.target_user?.last_name
                  }}
                </h1>
              </div>
        </div>
          </div>
        </div>
      </div>

      <div class="w-full p-6" v-if="currentView === 'requested'">
        <h3 class="mb-5 text-2xl font-bold uppercase">Requested</h3>
        <div class="overflow-x-auto flex flex-col gap-3 cursor-pointer">
          <div
            v-for="task in tasks.requested"
            :key="task.id"
            class="flex w-full border px-4 py-2 rounded-md justify-between flex-wrap gap-4"
            @click="navigateToTask(task.id)"
          >

            <div class="flex flex-col justify-between gap-5 w-9/12">
              <div>
                <h1 class="text-custom-green">message:</h1>
                <h1>{{ task?.message }}</h1>
              </div>
              <div class="flex gap-3">
                <h1 class="text-custom-green">Date:</h1>
                <h1>{{ task.created_at?.split("T")[0] }}</h1>
              </div>
            </div>
            <div class="flex gap-6 ml-auto">
              <div class="flex flex-col items-center gap-2">
                <h1 class="text-center text-custom-green">Client</h1>
                <template v-if="task.request_user?.profile?.photo_url">
                 <img
              :src="task.request_user?.profile?.photo_url"
              class="object-cover object-center w-20 h-20 rounded-full"
              />
          </template>
          <img
            v-else
            src="../assets/default_profile.png"
              class="object-cover object-center w-20 h-20 rounded-full"
          />
                <h1 class="text-center">
                  {{
                    task.type === "received"
                      ? "You"
                      : task.request_user?.first_name +
                        " " +
                        task.request_user?.last_name
                  }}
                </h1>
              </div>
              <div class="flex flex-col items-center gap-2">
                <h1 class="text-center text-custom-green">Tasker</h1>
                <template v-if="task.target_user?.profile?.photo_url">
                 <img
              :src="task.target_user?.profile?.photo_url"
              class="object-cover object-center w-20 h-20 rounded-full"
              />
          </template>
          <img
            v-else
            src="../assets/default_profile.png"
              class="object-cover object-center w-20 h-20 rounded-full"
          />
                <h1 class="text-center">
                  {{
                    task.type === "requested"
                      ? "You"
                      : task.target_user?.first_name +
                        " " +
                        task.target_user?.last_name
                  }}
                </h1>
              </div>
            </div>
        </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup () {
    const store = useStore()
    const tasks = ref([])
    const isLoading = ref(true)
    const currentView = ref('requested')
    const router = useRouter()

    const toggleView = (view) => {
      currentView.value = view
    }

    const navigateToTask = (taskId) => {
      router.push({ path: `/tasks/${taskId}` })
    }

    onMounted(async () => {
      await store.dispatch('data/getTasks')
      tasks.value = store.state.data.tasks
      isLoading.value = false
    })

    return { isLoading, tasks, currentView, toggleView, navigateToTask }
  }
}
</script>
