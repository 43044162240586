<template>
  <form @submit.prevent="onSubmit" novalidate id="serviceProviderProfileForm">
    <div class="grid items-center justify-center grid-cols-2 gap-4 mb-5">
      <label class="mb-1 font-semibold text-black">
        Are you a Service Provider</label
      >
      <div
        class="justify-center px-6 py-3 space-y-4 shadow-lg ring-2 ring-gray-100 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
      >
        <div class="flex items-center">
          <input
            id="yes"
            name="notification-method"
            v-model="v$.is_service_provider.$model"
            type="radio"
            :value="true"
            class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          />
          <label for="yes" class="block ml-3 font-medium text-gray-700">
            Yes
          </label>
        </div>
        <div class="flex items-center">
          <input
            id="no"
            name="notification-method"
            :value="false"
            type="radio"
            v-model="v$.is_service_provider.$model"
            class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          />
          <label for="no" class="block ml-3 font-medium text-gray-700">
            No
          </label>
        </div>
      </div>
    </div>
    <template v-if="v$.is_service_provider.$model">
    <div class="grid items-center justify-center grid-cols-2 gap-4 mb-5">
      <label class="mb-1 font-semibold text-black">
        Are you a Volunteer?</label
      >
      <div
        class="justify-center px-6 py-3 space-y-4 shadow-lg ring-2 ring-gray-100 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
      >
        <div class="flex items-center">
          <input
            id="is_volunteer_yes"
            name="is_volunteer"
            v-model="v$.is_volunteer.$model"
            type="radio"
            :value="true"
            class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          />
          <label for="is_volunteer_yes" class="block ml-3 font-medium text-gray-700">
            Yes
          </label>
        </div>
        <div class="flex items-center">
          <input
            id="is_volunteer_no"
            name="is_volunteer"
            :value="false"
            type="radio"
            v-model="v$.is_volunteer.$model"
            class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          />
          <label for="is_volunteer_no" class="block ml-3 font-medium text-gray-700">
            No
          </label>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4" v-if="!v$.is_volunteer.$model">
      <LabelTextInput
        class="mb-5"
        type="number"
        label="Rate (Hourly)"
        v-model="v$.hour_rate.$model"
        :icon="faEnvelope"
        :errors="v$?.hour_rate?.$errors[0]?.$message"
        :min="0"
        :step="0.25"
      />
    </div>
    <div class="flex flex-row flex-wrap" v-for="(services, key) in servicesData" :key="key">
    <h5 class="flex w-full text-xl font-semibold text-indigo-900">{{ key }}</h5>
      <div class="flex items-center justify-center p-3" v-for="(service, index) in services" :key="index">
        <input
          :id="service.id"
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          v-model="v$.services.$model"
          :value="service.id"
          class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
        />

        <label :for="service.id" class="block ml-3 font-medium text-gray-700">
          {{ service.name }}
        </label>
      </div>
    </div>
</template>
<div v-if="v$?.services?.$errors[0]?.$message" class="px-5 py-3 text-red-900 bg-red-100 rounded-sm">{{v$?.services?.$errors[0]?.$message}}</div>
    <SubmitButton class="px-20 my-5" :isLoading="isLoading"
      >Update Profile</SubmitButton
    >
    <div class="relative w-full">
      <div
        v-if="errors"
        class="absolute w-full p-4 font-medium text-red-800 rounded-md bg-red-50"
      >
        {{ errors }}
      </div>
        <div class="flex items-center px-4 py-2 mb-5 font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded text-md" v-if="messageAlert"> {{ messageAlert }}</div>

    </div>
  </form>
</template>
<script>
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import { reactive, ref } from '@vue/reactivity'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, requiredIf } from '@vuelidate/validators'
import axios from 'axios'
import { useStore } from 'vuex'
import LabelTextInput from './LabelTextInput.vue'
import SubmitButton from './SubmitButton.vue'

export default {
  components: { SubmitButton, LabelTextInput },
  setup (props, context) {
    const isLoading = ref(false)
    const passError = ref('')
    const messageAlert = ref('')
    const errors = ref('')
    const store = useStore()
    const servicesData = store.state.data.services
    const profile = ref(store.state.data.profile)

    const provinces = ref([
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Nova Scotia',
      'Northwest Territories',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Québec',
      'Saskatchewan',
      'Yukon'
    ])
    const state = reactive({
      first_name: profile?.value.first_name ?? '',
      last_name: profile?.value.last_name ?? '',
      address_line_1: profile?.value.address_line_1 ?? '',
      address_line_2: profile?.value.address_line_2 ?? '',
      phone_number: profile?.value.phone_number ?? '',
      city: profile?.value.city ?? '',
      postal_code: profile?.value.postal_code ?? '',
      province: profile?.value.province ?? '',
      bio: profile?.value.bio ?? '',
      hour_rate: profile?.value.hour_rate ?? '',
      is_service_provider: profile?.value.is_service_provider ?? false,
      is_volunteer: profile?.value.is_volunteer ?? false,
      services: profile?.value.services ?? [],
      photo_url: profile?.value.photo_url
    })

    const rules = {
      is_service_provider: {
        required: helpers.withMessage('Service provider field is required', required)
      },
      is_volunteer: {
        required: helpers.withMessage('Volunteer field is required', required)
      },
      hour_rate: {
        required: requiredIf(() => { return state.is_service_provider && !state.is_volunteer }),
        notNegative: helpers.withMessage('hour rate should be greater than 0', (value) => Number(value) > 0)

      },
      services: {
        required: requiredIf(() => { return state.is_service_provider })// requiredIf(state.is_service_provider)

      }
    }
    const v$ = useVuelidate(rules, state)

    const onSubmit = () => {
      v$.value.$touch()

      if (v$.value.$invalid) {
        document.getElementById('profileform').scrollIntoView({ behavior: 'smooth' })
        return
      }

      isLoading.value = true
      errors.value = null
      store
        .dispatch('data/updateProfile', state)
        .then(
          (data) => {
            messageAlert.value = 'Profile updated successfully.'
            profile.value = store.state.data.profile
            context.emit('profileUpdate')
          },
          (error) => {
            const err =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error ||
                error.toString()
            console.log('err', err)
            isLoading.value = false
          }
        )
        .then(function () {
          isLoading.value = false
        })
    }

    const uploadFile = async (e) => {
      const file = e.target.files[0]
      isLoading.value = true
      const formData = new FormData()
      formData.append('file', file)
      await axios.put('/upload/' + file.name, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          console.log(response)
          state.photo_url = response.data.link
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          isLoading.value = false
        })
      // fileUploadUrl
    }

    return {
      onSubmit,
      v$,
      faEnvelope,
      faLock,
      state,
      provinces,
      errors,
      uploadFile,
      servicesData,
      messageAlert,
      passError,
      isLoading
    }
  }
}
</script>
