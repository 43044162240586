<template>
  <div class="relative">
  <label v-if="label" class="mb-1 font-semibold text-xl text-indigo-900" for="">{{label}}</label>
  <div class="relative mt-1 rounded-md shadow-sm">
    <input
      :type="type"
      :class="`block w-full p-3 text-lg font-semibold border-0 shadow focus:border-b-1 focus:border-custom-green focus:ring-0 focus:shadow-lg ring-2 ring-gray-100 ${inputClass}`"
      :value="modelValue"
      :min="min"
      :step="step"
      @change="$emit('update:modelValue', $event.target.value)"
    />
  </div>
    <div if="errors" class="mt-1 text-sm text-red-500">{{errors}}</div>
  </div>
</template>
<script>
export default {
  props: {

    errors: {
      default: null
    },
    modelValue: { type: String },
    label: { default: null },
    inputClass: { default: null },
    type: {
      default: 'text'
    },
    min: {
      default: null
    },
    step: {
      default: null
    }
  },
  components: {
  },
  setup () {}
}
</script>
