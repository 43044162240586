<template>
  <div class="relative overflow-x-hidden min-h-screen">
    <section class="w-full text-white  home-shape" data-aos="fade-up">
      <div class="px-4 py-32 mx-auto max-w-screen-xl  lg:h-screen lg:items-center lg:flex">
        <div class="max-w-3xl mx-auto text-center">
          <h1
            class="text-3xl font-extrabold text-transparent sm:text-6xl bg-clip-text bg-gradient-to-r from-custom-green-dark via-custom-green-light to-blue-400"
          >
          MAKE YOUR LIFE EASIER
            <!-- <span class="sm:block pb-3">  </span> -->
          </h1>

          <p class="max-w-xl mx-auto mt-4 sm:leading-relaxed text-xl sm:text-2xl">
            Hire on-demand taskers to help you with your day-to-day chores while you focus on more important things.
          </p>

          <div class="flex flex-wrap justify-center mt-8 gap-4">
            <a
              href="/register"
              class="w-full sm:w-auto justify-center inline-flex items-center px-8 py-3 text-white bg-custom-green-dark border border-custom-green-dark rounded hover:bg-transparent hover:text-custom-green-dark active:text-indigo-500 focus:outline-none focus:ring"
            >
              <span class="text-base font-medium"> Get Started </span>
              <svg
                class="w-5 h-5 ml-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>
            <a
              class="w-full inline-flex  justify-center px-12 py-3 text-base font-medium text-custom-green-dark hover:text-white border border-custom-green-dark rounded sm:w-auto hover:bg-custom-green active:bg-custom-green focus:outline-none focus:ring"
              href="/login"
            >
              Login
            </a>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="relative bg-white py-8 sm:py-12 lg:py-16">
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div class="">
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div class="" v-for="(item, index) in features" :key="index">
                <div class="flow-root bg-custom-green-light rounded-lg h-full px-6 pb-8">
                  <div class="">
                    <h3 class="mt-8 text-lg font-medium text-green-800 tracking-tight">{{item.title}}</h3>
                    <p class="mt-5 text-base text-green-700">{{item.content}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </section>
    <section class="bg-custom-green-light bg-opacity-25">
      <div class="max-w-screen-xl overflow-hidden  px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
          <div
            class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:h-full lg:order-last"
            data-aos="fade-left"
          >
            <img
              alt="Party"
              src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
              class="absolute inset-0 object-cover w-full h-full"
            />
          </div>

          <div class="lg:py-24" data-aos="fade-right">
            <h2 class="text-3xl font-bold sm:text-4xl">No More Inexperienced, Unreliable Helpers and No Shows</h2>

            <p class="mt-4 text-gray-600">
              Save yourself from unwanted frustration and annoying delays. For far too long, people have had to rely on luck to find a reliable, experienced professional online. With Husky Task, you don’t have to go through all that as all our service providers are trained to be top-quality professionals who are always on time.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="relative bg-gray-900" data-aos="flip-up">
      <div
        class="relative h-56 bg-custom-green-dark sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2"
      >
        <img
          class="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
          alt=""
        />
        <div
          aria-hidden="true"
          class="absolute inset-0 bg-gradient-to-r from-custom-green-dark to-custom-green-light mix-blend-multiply"
        />
      </div>
      <div
        class="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32"
      >
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <p class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            Get Help With…
          </p>
          <p class="mt-3 mb-6 text-lg text-gray-300">
            Find a professional handyman, hire a mover, get cleaners for your home, or cook and event planners for your party with just a few taps on our app. You can even set up an appointment with a car mechanic directly from the app. How great is that!
          </p>
          <a
              href="/register"
              class="w-full sm:w-auto justify-center inline-flex items-center px-8 py-3 text-white bg-custom-green-dark border border-custom-green-dark rounded hover:bg-transparent hover:text-custom-green-dark active:text-indigo-500 focus:outline-none focus:ring"
            >
              <span class="text-base font-medium"> Find A Helper </span>
              <svg
                class="w-5 h-5 ml-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>

        </div>
      </div>
    </div>
    <div class="text-center py-20 md:py-40" data-aos="flip-up">
      <h1
        class="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl"
      >
        <span class="block xl:inline">Introducing the Personal Grocery Shopper</span>
      </h1>
      <p
        class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
      >
      Don’t waste hours and hours of your important time shopping and organizing your grocery every single month. Now, you can have your very own grocery shopper who will not only do your grocery, but will also deliver, and organize it, so you can save your time and money. How about that?
      </p>
      <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
        <div class="rounded-md shadow">
          <a
            href="/register"
            class="inline-flex items-center px-8 py-3 text-white bg-custom-green-dark border border-custom-green-dark rounded hover:bg-transparent hover:text-custom-green-dark active:text-indigo-500 focus:outline-none focus:ring"
          >
            <span class="text-sm font-medium"> Hire A Grocery Shopper</span>
            <svg
              class="w-5 h-5 ml-3"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <section>
      <div class="relative bg-custom-green-dark bg-opacity-20 py-8 sm:py-12 lg:py-16">
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div class="">
            <h1
            class="text-xl tracking-tight mb-4 md:mb-8 font-extrabold text-gray-900 sm:text-2xl md:text-3xl"
          >
            <span class="block xl:inline">3 Easy Steps to Get the Right Help for Your Everyday Tasks</span>
          </h1>
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div class="" v-for="(item, index) in processSteps" :key="index">
                <div class="flow-root bg-gray-800 rounded-lg h-full px-6 pb-8">
                  <div class="">
                    <h3 class="mt-8 text-lg font-medium text-gray-100 tracking-tight">{{item.title}}</h3>
                    <p class="mt-5 text-base text-gray-100">{{item.content}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </section>
    <div class="bg-gray-900" data-aos="fade-up">
      <div class="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12">
          <div class="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 class="text-3xl text-white font-extrabold tracking-tight sm:text-4xl">
              Our Top-Rated Taskers
            </h2>
            <p class="mt-3 max-w-md mx-auto text-base text-gray-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"> All our service providers strive to provide the best services to you. With our helpers, you can have the peace of mind of getting your chores done at the right time in the right way.</p>
          </div>
          <ul
            role="list"
            class="mx-auto space-y-16  sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-4 lg:max-w-7xl"
          >
            <li v-for="(tasker, index) in taskers" :key="index">
              <div class="space-y-6 text-left">
                <img
                  class="rounded w-full object-cover object-center h-80 md:h-64"
                  :src="tasker.image"
                  alt=""
                />
                <div class="space-y-2">
                  <div class="text-lg leading-6 font-medium space-y-1">
                    <h3 class="text-white text-left">{{tasker.name}}</h3>
                    <p class="text-custom-green-dark text-left">{{tasker.subtitle}}</p>
                    <p class=" text-gray-200 text-left">{{tasker.description}}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-custom-green bg-opacity-10">
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <div>
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
            <span class="block">Are you ready to make your life easier?</span>
          </h2>
            <p class="block text-base  text-custom-green">Register yourself today on our app and start
              finding your desired helper.
              You’ll love it. We promise!</p>
            </div>
          <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div class="inline-flex rounded-md shadow">
              <a href="/register" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-l from-green-400 bg-custom-green-dark"> Get started </a>
            </div>
          </div>
        </div>
      </div>

    <div class="bg-custom-green-dark" data-aos="zoom-in">
        <div class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
            <span class="block">Are you a service provider looking for work?</span>
            <span class="block">Become a Husky tasker and start working today. </span>
          </h2>
          <a
          href="/register"
          class="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-custom-green-dark bg-white hover:bg-indigo-50 sm:w-auto"
        >
        Become a Husky Tasker
        </a>
        </div>
      </div>

  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import AOS from 'aos'
import provider1 from '@/assets/provider1.jpg'
import provider2 from '@/assets/provider2.jpg'
import provider3 from '@/assets/provider3.jpg'
import provider4 from '@/assets/provider4.jpg'
const features = reactive([
  { title: 'Reliable', content: 'All our service providers are carefully vetted to make sure you get only the best services.' },
  { title: 'Full Control', content: 'You decide the helper, the pricing, the time, and the terms you want to hire on.' },
  { title: 'Fast & Efficient', content: 'Search for services you need, see available Husky taskers, and send requests, all within seconds.' }
])
const processSteps = reactive([
  { title: 'Step One', content: 'Open our app and choose the tasker you need after comparing reviews, skills, and prices. ' },
  { title: 'Step Two', content: 'Send an email, and schedule as per your convenience with the tasker.' },
  { title: 'Step Three', content: 'The tasker will arrive at the scheduled time and get the work done —p  ay and review.' }
])
const taskers = reactive([
  {
    image: provider1,
    name: 'John Walton',
    subtitle: 'Handy Man',
    description: 'All around handy man, sevrvices include (but not limited to) installing curtain rods, hanging pictures, assembling furniture, cutting wood, making minor plumbing fixes, and changing light bulbs.'
  },
  {
    image: provider2,
    name: 'Ajaz',
    subtitle: 'Plumber',
    description: '5+ years of plumbing experience, services include: water leak inspection, installing new fixtures, unclogging and more.'
  },
  {
    image: provider3,
    name: 'Jared',
    subtitle: 'Helper',
    description: 'Grocery , cleaning, driving, home organizing, furniture assembly and delivery.'
  },
  {
    image: provider4,
    name: 'Aaron',
    subtitle: 'Electrician',
    description: 'Certificed electrician! no job is too small.'
  }
])
onMounted(() => {
  AOS.init()
})
</script>
<style scoped>
.home-shape{
    width: 100%;
    height: 100%;

    background-image:linear-gradient(rgba(68, 68, 68, 0.85), rgba(32, 33, 36, 0.85)), url('/img/bg-shape.6897b9f8.png');
    background-repeat: no-repeat;
    background-position: top right;
    top: 0;
}
</style>
